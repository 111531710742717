import React from "react";
import { Doughnut } from "react-chartjs-2";
import propTypes from "prop-types";

import { cssvar, pieDefaults } from "./chartDefaults";

// Expects data structured like: data=[{ label: ..., value: ...}, ...]
const processData = data => {
  return [data.map(el => el.label), data.map(el => el.value)];
};

function DoughnutChart(props) {
  let legendExtension = {};
  if (props.hideLegend) {
    legendExtension = { display: false };
  }

  return (
    <Doughnut
      data={pieDefaults(processData(props.data))}
      options={{
        maintainAspectRatio: false,
        title: {
          display: true,
          fontColor: cssvar("--color-gray-8"),
          text: props.chartLabel
        },
        legend: {
          ...legendExtension,
          labels: {
            fontColor: cssvar("--color-gray-8")
          }
        }
      }}
    />
  );
}

DoughnutChart.defaultProps = {
  chartLabel: ""
};

DoughnutChart.propTypes = {
  // One array, structured like: data=[{ label: ..., value: ...}, ...]
  data: propTypes.oneOfType([propTypes.array]).isRequired,
  chartLabel: propTypes.string // Title of the chart
};

export default DoughnutChart;
