import Chart from "chart.js";
import "chartjs-plugin-crosshair";
// This is a relentless hack to get ^ that stupid plugin to work pie/doughnut charts
Chart.defaults.global.scales = {
  yAxes: [],
  xAxes: [
    {
      type: "time",
      display: false,
      time: {
        tooltipFormat: "MMM DD, YYYY, hh:mm a"
      }
    }
  ]
};

// https://github.com/chartjs/Chart.js/issues/2077
// https://codepen.io/kurkle/pen/JjoXXXv
// Used to all chartjs to use css variables
// @note: charts in the ucl will NOT be able to benefit from this
export function cssvar(name) {
  if (typeof document !== "undefined")
    return getComputedStyle(document.documentElement).getPropertyValue(name);
  return null;
}

export const datasetDefaults = (data, index = 0, label, fill) => ({
  label: label,
  lineTension: 0.1,
  showLine: true,
  fill: fill,
  pointStyle: "rectRounded",
  backgroundColor: companyColorFillDefaults[index],
  borderColor: companyColorDefaults[index],
  pointBorderColor: companyColorDefaults[index],
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: companyColorDefaults[index],
  pointHoverBorderColor: companyColorDefaults[index],
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  data: data
});

export const lineOptions = (
  data = [],
  chartLabel = "",
  axisLabels = ["", ""],
  yFormatter,
  yTooltipFormatter,
  dataLabels = [],
  fromZero
) => {
  let legendExtension = {
    display: false
  };
  let tickExtension = {};
  if (dataLabels.length > 1) legendExtension = {};
  if (fromZero) tickExtension = { beginAtZero: true };
  return {
    maintainAspectRatio: false,
    title: {
      display: true,
      fontColor: cssvar("--color-gray-6"),
      text: chartLabel
    },
    legend: {
      ...legendExtension,
      labels: {
        fontColor: cssvar("--color-gray-6"),
        usePointStyle: true
      }
    },
    scales: {
      xAxes: [
        {
          type: "time",
          gridLines: {
            display: false
          },
          displayFormat: {
            month: "MMM"
          },
          scaleLabel: {
            display: true,
            labelString: axisLabels[0],
            fontColor: cssvar("--color-gray-6")
          },
          ticks: {
            autoSkipPadding: 30,
            autoSkip: true,
            fontColor: cssvar("--color-gray-6"),
            maxRotation: 0
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: axisLabels[1],
            fontColor: cssvar("--color-gray-6")
          },
          ticks: {
            callback:
              yFormatter ||
              function(value, index, values) {
                return value;
              },
            fontColor: cssvar("--color-gray-6"),
            maxTicksLimit: 6,
            ...tickExtension
          }
        }
      ]
    },
    plugins: {
      crosshair: {
        line: {
          color: cssvar("--color-gray-4"), // crosshair line color
          width: 1 // crosshair line width
        },
        sync: {
          enabled: false,
          suppressTooltips: false // suppress tooltips when showing a synced tracer
        },
        zoom: {
          enabled: false
        },
        snapping: {
          enabled: true
        }
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItems, data) {
          return `${
            data.datasets[tooltipItems.datasetIndex].label
          }: ${yTooltipFormatter(tooltipItems.yLabel)}`;
        }
      },

      mode: "index",
      intersect: false
    },
    hover: {
      mode: "index",
      intersect: false
    }
  };
};

export const pieDefaults = data => {
  return {
    labels: data[0],
    datasets: [
      {
        cutoutPercentage: 50,
        data: data[1],
        backgroundColor: pieColors,
        hoverBackgroundColor: pieColorsHovered,
        borderColor: cssvar("--color-gray-1")
      }
    ]
  };
};

export const calendarTheme = {
  monthBorderColor: "var(--color-gray-1)",
  dayBorderColor: "var(--color-gray-1)",
  emptyColor: "var(--color-gray-2)",
  legends: [
    {
      anchor: "bottom-right",
      itemTextColor: "var(--color-gray-8)",
      direction: "row",
      translateY: 36,
      itemCount: 4,
      itemWidth: 42,
      itemHeight: 36,
      itemsSpacing: 14,
      itemDirection: "right-to-left"
    }
  ],
  theme: {
    labels: {
      text: {
        fill: "var(--color-gray-8)"
      }
    },
    tooltip: {
      container: {
        background: "var(--color-gray-1)",
        color: "var(--color-gray-8)"
      }
    }
  }
};

export const calendarDefaults = () => ({
  from: "2019-08-01",
  to: "2019-12-12",
  colors: [
    "rgba(105, 58, 250, 0.5)",
    "rgba(105, 58, 250, 0.9)",
    "rgba(0, 209, 178, 0.5)",
    "rgba(0, 209, 178, 0.9)"
  ],
  margin: { top: 40, right: 40, bottom: 40, left: 40 },
  yearSpacing: 40,
  dayBorderWidth: 2
});

export const companyColorDefaults = [
  "rgba(105, 58, 250, 1)",
  "rgba(0, 209, 178, 1)",
  "rgba(236, 140, 68, 1)"
];
export const companyColorFillDefaults = [
  "rgba(105, 58, 250, 0.4)",
  "rgba(0, 209, 178, 0.4)",
  "rgba(236, 140, 68, 0.4)"
];

export const companyColorFillAlts = [
  "rgba(105, 58, 250, 0.8)",
  "rgba(0, 209, 178, 0.8)",
  "rgba(236, 140, 68, 0.8)"
];

const pieColors = [
  companyColorFillAlts[0],
  companyColorFillDefaults[0],
  companyColorFillDefaults[1],
  companyColorFillAlts[1],
  companyColorFillAlts[2],
  companyColorFillDefaults[2]
];

const pieColorsHovered = [
  companyColorDefaults[0],
  companyColorFillAlts[0],
  companyColorFillAlts[1],
  companyColorDefaults[1],
  companyColorDefaults[2],
  companyColorFillAlts[2]
];
