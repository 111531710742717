import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { Line } from "react-chartjs-2";

import { datasetDefaults, lineOptions } from "./chartDefaults";

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Takes in data and tries to map it to the expected dataset. Supports 2D and 1D arrays
 * (2D arrays imply multiple lines)
 *
 * @param array any array of data
 * @returns data model expected by chartjs
 */
function processData(data, labels, fill) {
  // Check if `data` is not a 2D array
  if (data[0].constructor !== Array) {
    // if not, make it one
    data = [data];
  }

  return {
    datasets: data.map((line, i) =>
      datasetDefaults(
        line.map(el => ({ x: el.date, y: el.value })),
        i,
        labels[i] || `Line ${i}`,
        fill
      )
    )
  };
}

function LineChart(props) {
  // Empty State
  if (!props.data.length) return props.emptyState;
  return (
    <Line
      data={processData(props.data, props.dataLabels || [], props.isArea)}
      options={lineOptions(
        props.data,
        props.chartLabel,
        props.axisLabels,
        props.yFormatter,
        props.yTooltipFormatter,
        props.dataLabels,
        props.fromZero
      )}
    />
  );
}

LineChart.defaultProps = {
  axisLabels: ["", ""],
  chartLabel: "",
  isArea: false,
  yTooltipFormatter: el => el,
  emptyState: EmptyState
};

LineChart.propTypes = {
  data: propTypes.oneOfType([propTypes.array]).isRequired,
  axisLabels: propTypes.array, // Label shown beside each axis
  chartLabel: propTypes.string, // Title of the chart
  dataLabels: propTypes.array, // Name of each line
  fromZero: propTypes.bool,
  isArea: propTypes.bool.isRequired,
  yFormatter: propTypes.func, // A function to format the yAxis labels
  yTooltipFormatter: propTypes.func, // A function to format the yAxis labels
  emptyState: propTypes.node
};

export default LineChart;
